<template>
  <div class="max-width-700px">
    <!-- VXC Foreign Ownership Restricted Speed Changes Banner -->
    <div v-if="(!aEndOwned || !bEndOwned) && !isCloudConnection && !serviceIsIx && !pendingSpeedApprovalMessage"
      class="non-editable mx-auto mb-2">
      <p v-if="!aEndOwned">
        {{ $t('connections.speed-req-external-approval-a-end') }}
      </p>
      <p v-if="!bEndOwned">
        {{ $t('connections.speed-req-external-approval-b-end') }}
      </p>
    </div>

    <!-- Speed Changes Pending Approval Block -->
    <div v-if="pendingSpeedApprovalMessage"
      class="non-editable mx-auto mb-2">
      <p v-html="pendingSpeedApprovalMessage" /> <!-- eslint-disable-line vue/no-v-html -->
      <template v-if="approvalPending.type === 'SPEED_CHANGE'">
        <div class="text-align-center">
          {{ $t('connections.old-speed') }}: <strong>{{ approvalPending.oldSpeed }} Mbps</strong>, {{ $t('connections.new-speed') }}:
          <strong>{{ approvalPending.newSpeed }} Mbps</strong>
        </div>

        <template v-if="canApply && approvalPending.internal && approvalPending.permitted">
          <div class="my-1">
            {{ $t('connections.approve-speed-question') }}
          </div>
          <el-form>
            <div class="text-align-right">
              <el-button type="danger"
                data-testid="reject-button"
                name="denySpeedChange"
                @click="$emit('requestdenied')">
                {{ $t('connections.deny-speed-change') }}
              </el-button>
              <el-button type="success"
                data-testid="approve-button"
                name="approveSpeedChange"
                @click="$emit('requestapproved')">
                {{ $t('connections.approve-speed-change') }}
              </el-button>
            </div>
          </el-form>
        </template>
        <div v-else
          class="text-align-center my-1">
          <button class="el-button el-button--danger"
            name="cancelSpeedChange"
            @click="$emit('requestdenied')">
            {{ $t('connections.cancel-speed-request') }}
          </button>
        </div>
      </template>
    </div>

    <!-- Field: Connection Name -->
    <el-form-item v-if="aEndPort._owned || serviceIsIx"
      prop="productName"
      :label="$t('connections.connection-name')"
      label-width="250px">
      <el-input :value="connectionForm.productName"
        name="productName"
        data-testid="connection-name"
        @input="connectionFormChange('productName', $event)" />
    </el-form-item>

    <!-- Field: Connection Name (Secondary) -->
    <el-form-item v-else
      prop="secondaryName"
      :label="$t('connections.connection-name')"
      label-width="250px">
      <el-input :value="connectionForm.secondaryName"
        name="secondaryName"
        data-testid="connection-name"
        @input="connectionFormChange('secondaryName', $event)" />
    </el-form-item>

    <!-- Field: Rate Limit -->
    <el-form-item prop="rateLimit"
      label-width="250px">
      <template #label>
        {{ $t('services.rate-limit') }}
        <el-tooltip placement="top"
          :content="serviceIsIx ? $t('connections.ix-rate-limit-help') : $t('connections.vxc-rate-limit-help')"
          :open-delay="500">
          <i class="fas fa-question-circle color-info popover-info-icon"
            aria-hidden="true" />
        </el-tooltip>
      </template>

      <template v-if="showRateLimitWarning || enforcedSpeedChange">
        <span class="color-text-regular"
          data-testid="rate-limit-display">
          {{ connectionForm.rateLimit }} Mbps
        </span>
        <div class="non-editable">
          {{ $t(showRateLimitWarning ? 'services.rate-limit-fixed' : 'nutanix.accept-speed-change') }}
        </div>
      </template>
      <template v-else-if="approvalPending.type === 'SPEED_CHANGE'">
        <span class="color-text-regular"
          data-testid="rate-limit-display">
          {{ approvalPending.oldSpeed }} Mbps
        </span>
        <div v-if="!approvalPending.internal"
          class="non-editable">
          {{ $t('connections.pending-speed-approval-to', { newSpeed: approvalPending.newSpeed, approvalFrom: approvalPending.message }) }}
        </div>
        <div v-else
          class="non-editable">
          {{ $t('connections.pending-speed-approval-to', { newSpeed: approvalPending.newSpeed, approvalFrom: approvalPending.permitted ? $t('general.you') : $t('general.your-company-admin') }) }}
        </div>
      </template>
      <input-ratelimit v-else
        :value="connectionForm.rateLimit"
        :min-rate-limit="minRateLimit"
        :max-rate-limit="maxRateLimit"
        :fixed-bandwidths="fixedBandwidths"
        :fixed-speed-service="showRateLimitWarning"
        :fixed-rate="false"
        :higher-speed-url="higherSpeedUrl"
        :provisioning-status="service.provisioningStatus"
        @input="connectionFormChange('rateLimit', $event)" />
    </el-form-item>

    <div v-if="!serviceIsIx && service.contractTermMonths > 1"
      class="non-editable mb-1-5 ml-250px">
      {{ $t('connections.rate-limit-change-term', { termSpeed: minRateLimit }) }}
    </div>

    <!-- A-End vNIC -->
    <el-form-item v-if="showAEndVNics"
      prop="aEnd.vNicIndex"
      label-width="250px">
      <template #label>
        {{ $t('connections.end-vnic', { end: 'A' }) }}
        <el-tooltip placement="top"
          :content="$t('connections.a-end-vnic-help')"
          :open-delay="500">
          <i class="fas fa-question-circle color-info popover-info-icon"
            aria-hidden="true" />
        </el-tooltip>
      </template>
      <el-select :value="connectionForm.aEnd.vNicIndex"
        class="full-width"
        data-testid="vnic-select"
        @change="handleVNicChangeForEnd('a', $event)">
        <el-option v-for="(vnic, index) in aEndPort.vnics"
          :key="`vNIC-${index}`"
          :label="`vNIC-${index} ${vnic.description || ''}`"
          :value="index" />
      </el-select>
    </el-form-item>

    <!-- Field: Preferred A-End Inner VLAN -->
    <input-vlan v-if="aEndIsMve"
      ref="aEnd.innerVlan"
      prop="aEnd.innerVlan"
      end="a"
      :value="connectionForm.aEnd.innerVlan"
      :second-value="connectionForm.aEnd.vlan"
      :original-vlan="getOriginalAEndVlan"
      :original-inner-vlan="getOriginalAEndInnerVlan"
      :service="editingService"
      :disabled="aEndVlanFieldDisabled"
      label-width="250px"
      @input="connectionFormChange('aEnd.innerVlan', $event)" />

    <!-- Field: Preferred A-End VLAN -->
    <input-vlan v-else-if="canEditAEndVlan"
      ref="aEnd.vlan"
      prop="aEnd.vlan"
      end="a"
      :value="connectionForm.aEnd.vlan"
      :service="service"
      :disabled="aEndVlanFieldDisabled"
      label-width="250px"
      @input="connectionFormChange('aEnd.vlan', $event)" />

    <!-- B-End vNIC -->
    <el-form-item v-if="showBEndVNics"
      prop="bEnd.vNicIndex"
      label-width="250px">
      <template #label>
        {{ $t('connections.end-vnic', { end: 'B' }) }}
        <el-tooltip placement="top"
          :content="$t('connections.b-end-vnic-help')"
          :open-delay="500">
          <i class="fas fa-question-circle color-info popover-info-icon"
            aria-hidden="true" />
        </el-tooltip>
      </template>
      <el-select :value="connectionForm.bEnd.vNicIndex"
        class="full-width"
        data-testid="vnic-select"
        @change="handleVNicChangeForEnd('b', $event)">
        <el-option v-for="(vnic, index) in bEndPort.vnics"
          :key="`vNIC-${index}`"
          :label="`vNIC-${index} ${vnic.description || ''}`"
          :value="index" />
      </el-select>
    </el-form-item>

    <!-- Field: Preferred B-End Inner VLAN -->
    <input-vlan v-if="bEndIsMve"
      ref="bEnd.innerVlan"
      prop="bEnd.innerVlan"
      end="b"
      :value="connectionForm.bEnd.innerVlan"
      :second-value="connectionForm.bEnd.vlan"
      :original-vlan="getOriginalBEndVlan"
      :original-inner-vlan="getOriginalBEndInnerVlan"
      :service="editingService"
      label-width="250px"
      @input="connectionFormChange('bEnd.innerVlan', $event)" />

    <!-- Field: Preferred B-End VLAN -->
    <input-vlan v-else-if="canEditBEndVlan"
      ref="bEnd.vlan"
      prop="bEnd.vlan"
      end="b"
      :value="connectionForm.bEnd.vlan"
      :service="service"
      label-width="250px"
      @input="connectionFormChange('bEnd.vlan', $event)" />

    <!-- IX-Specific Fields -->
    <template v-if="serviceIsIx">
      <!-- IX Field: ASN -->
      <el-form-item prop="asn"
        label-width="250px">
        <template #label>
          {{ $t('connections.asn') }}
          <el-tooltip placement="top"
            :content="$t('connections.asn-full')"
            :open-delay="500">
            <i class="fas fa-question-circle color-info popover-info-icon"
              aria-hidden="true" />
          </el-tooltip>
        </template>

        <el-input :value="connectionForm.asn"
          name="asn"
          data-testid="ix-asn"
          @input="connectionFormChange('asn', $event)" />
      </el-form-item>

      <!-- IX Field: BGP Password -->
      <el-form-item prop="password"
        label-width="250px">
        <template #label>
          {{ $t('connections.bgp-password') }}
          <el-tooltip placement="top"
            :content="$t('ix.bgp-password-tooltip')"
            :open-delay="500">
            <i class="fas fa-question-circle color-info popover-info-icon"
              aria-hidden="true" />
          </el-tooltip>
        </template>

        <el-input :value="connectionForm.password"
          name="password"
          data-testid="ix-bgp-password"
          :type="showPassword ? 'text' : 'password'"
          @input="connectionFormChange('password', $event)">
          <template #suffix>
            <i :class="showPassword ? 'fa-eye' : 'fa-eye-slash'"
              class="mr-1 cursor-pointer"
              @click="showPassword = !showPassword" />
          </template>
        </el-input>
      </el-form-item>

      <!-- IX Field: Preferred A-End VLAN -->
      <input-vlan ref="vlan"
        :value="connectionForm.vlan"
        prop="vlan"
        :service="service"
        :end="'a'"
        label-width="250px"
        @input="connectionFormChange('vlan', $event)" />

      <!-- IX Field: MAC Address -->
      <mac-address :value="connectionForm.macAddress"
        validation-key="macAddress"
        label-width="250px"
        @input="connectionFormChange('macAddress', $event)" />

      <!-- IX Field: Graph Visibility -->
      <el-form-item prop="publicGraph"
        :label="$t('connections.graph-visibility')"
        label-width="250px">
        <el-radio-group :value="connectionForm.publicGraph"
          name="publicGraph"
          @input="connectionFormChange('publicGraph', $event)">
          <el-radio-button :label="true"
            class="inverse-padding">
            <el-tooltip placement="top"
              :content="$t('ix.looking-glass-graphs')"
              :open-delay="500">
              <span>{{ $t('general.public') }}</span>
            </el-tooltip>
          </el-radio-button>
          <el-radio-button :label="false"
            class="inverse-padding">
            <el-tooltip placement="top"
              :content="$t('ix.no-looking-glass-graphs')"
              :open-delay="500">
              <span>{{ $t('general.private') }}</span>
            </el-tooltip>
          </el-radio-button>
        </el-radio-group>
      </el-form-item>

      <!-- IX Field: Peer Macro -->
      <el-form-item v-if="showEcix"
        prop="ixPeerMacro"
        :label="$t('connections.peer-macro')"
        label-width="250px">
        <el-input :value="connectionForm.ixPeerMacro"
          type="text"
          name="ixPeerMacro"
          @input="connectionFormChange('ixPeerMacro', $event)" />
      </el-form-item>

      <!-- IX Field: Reverse DNS -->
      <el-form-item prop="reverseDns"
        label-width="250px">
        <template #label>
          {{ $t('connections.reverse-dns') }}
        </template>
        <el-input :value="connectionForm.reverseDns"
          type="text"
          name="reverseDns"
          @input="connectionFormChange('reverseDns', $event)" />
      </el-form-item>
    </template>

    <!-- Field: Partner Deals -->
    <el-form-item v-if="showDeals"
      prop="deals"
      :label="$t('general.partner-deals')"
      label-width="250px">
      <deal-select :form-data="connectionForm"
        :deals="deals"
        :service-data="service"
        @updateDealUid="connectionFormChange('dealUid', $event)" />
    </el-form-item>

    <!-- Field: Service Level Reference -->
    <el-form-item v-if="aEndOwned"
      prop="costCentre"
      label-width="250px">
      <template #label>
        {{ $t('services.invoice-reference') }}
        <el-tooltip placement="top"
          :open-delay="500">
          <template #content>
            <p class="tooltip-p">
              {{ $t('services.invoice-reference-explanation') }}
            </p>
            <i18n v-if="!disabledFeatures.knowledgeBase"
              path="connections.connections-html"
              tag="p"
              class="tooltip-p">
              <template #link>
                <a href="https://docs.megaport.com/connections/"
                  target="_blank">{{ $t('general.documentation') }}</a>
              </template>
            </i18n>
          </template>
          <i class="fas fa-question-circle color-info popover-info-icon"
            aria-hidden="true" />
        </el-tooltip>
      </template>
      <el-input :value="connectionForm.costCentre"
        name="costCentre"
        @input="connectionFormChange('costCentre', $event)" />
    </el-form-item>

    <!-- Field: VXC/IX State -->
    <el-form-item prop="shutdown"
      label-width="250px">
      <template #label>
        {{ $t('services.type-state', { type: $t(serviceIsIx ? 'productNames.ix' : 'productNames.vxc') }) }}
        <el-tooltip placement="top"
          :content="$t('tooltips.shutdown-state')"
          :open-delay="500">
          <i class="fas fa-question-circle color-info popover-info-icon"
            aria-hidden="true" />
        </el-tooltip>
      </template>
      <!-- Don't allow the B-End of a cross-company VXC to shut it down -->
      <el-radio-group v-if="aEndOwned"
        :value="connectionForm.shutdown"
        name="shutdown"
        @input="shutdownChange($event)">
        <el-radio-button :label="false"
          class="inverse-padding">
          {{ $t('general.enabled') }}
        </el-radio-button>
        <el-radio-button :label="true"
          class="inverse-padding">
          {{ $t('general.shut-down') }}
        </el-radio-button>
      </el-radio-group>
      <span v-else
        class="color-text-regular">
        {{ $t(connectionForm.shutdown ? 'general.shut-down' : 'general.enabled') }}
      </span>
    </el-form-item>

    <!-- Contract Terms / Subscription -->
    <template v-if="!serviceIsIx && (service.contractEndDate || service.contractTermMonths)">
      <el-form-item :label="$t(isPartnerVantage ? 'partner-vantage.subscription' : 'ports.terms')"
        label-width="250px">
        <!-- End Date -->
        <p v-if="showTerm"
          class="m-0">
          <!-- Label -->
          <strong class="pr-0-5">{{ $t('general.end-date') }}: </strong>
          <!-- Value -->
          <span v-if="service.contractEndDate && service.provisioningStatus === G_PROVISIONING_LIVE"
            data-testid="term-end-date">
            {{ formatDate(service.contractEndDate) }}
          </span>
          <span v-else>
            {{ $t('ports.end-date-display', { productName }) }}
          </span>
        </p>
        <!-- Term -->
        <p v-if="service.contractTermMonths"
          class="m-0">
          <!-- Label -->
          <strong class="pr-0-5">{{ $t('general.term') }}: </strong>
          <!-- Value -->
          <span data-testid="term-months">{{ termDisplay }}</span>
        </p>
        <!-- Change Contract Term Button -->
        <template v-if="allowContractTermEdit">
          <el-button class="mr-1"
            data-testid="change-term-button"
            @click="showTermModal = true">
            {{ $t(isPartnerVantage ? 'partner-vantage.change-subscription' : 'ports.change-term') }}
          </el-button>
          <a v-if="!disabledFeatures.knowledgeBase && !isPartnerVantage"
            href="https://docs.megaport.com/finance/vxc-pricing/"
            target="_blank"
            rel="noopener"
            class="d-block my-0">
            {{ $t('ports.term-info', { productName }) }}
          </a>
        </template>
      </el-form-item>
    </template>

    <!--Azure Connection Inner VLAN -->
    <section v-if="(bEndPort.connectType === 'AZURE') && (aEndIsPort || aEndIsMve)"
      class="azure-settings-group">
      <!-- Heading -->
      <h5 class="text-align-center mt-1 mb-2">
        {{ $t('connections.azure-vlan') }}
      </h5>

      <!-- Configure single Azure peering VLAN -->
      <el-form-item prop="usingAzurePeering"
        label-width="375px"
        :label="$t('connections.configure-azure-vlan')">
        <el-switch :value="usingAzurePeering"
          data-name="usingAzurePeering"
          @change="usingAzurePeeringChanged" />
        <!-- Documentation Link -->
        <a href="https://docs.megaport.com/cloud/megaport/microsoft/"
          target="_blank"
          rel="noopener"
          class="ml-1">{{ $t('general.documentation') }}</a>
      </el-form-item>

      <!-- Azure Peering VLAN -->
      <el-form-item ref="innerVlan"
        prop="bEnd.innerVlan"
        label-width="375px"
        :label="$t('connections.azure-vlan')">
        <el-input :value="connectionForm.bEnd.innerVlan"
          :disabled="!usingAzurePeering"
          :placeholder="$t('connections.enter-vlan')"
          data-name="innerVlan"
          data-demo="42"
          @input="connectionFormChange('bEnd.innerVlan', $event)" />
      </el-form-item>
    </section>

    <!-- Confirm Shutdown Dialog -->
    <simple-confirmation-dialog :visible="showShutdownModal"
      @cancel="cancelShutdown"
      @confirm="showShutdownModal = false">
      <template #title>
        <h3>{{ $t('services.shutdown-dialog-title') }}</h3>
      </template>
      <p class="mt-0">
        {{ $t('services.shutdown-dialog-message') }} {{ $t('services.shutdown-confirm-message') }}
      </p>
      <strong>{{ $t('services.shutdown-dialog-note') }}</strong>
    </simple-confirmation-dialog>

    <!-- Contract Term Modal -->
    <port-term v-if="showTermModal"
      :visible.sync="showTermModal"
      :port="service"
      :min-rate-limit="minRateLimit"
      :max-rate-limit="maxRateLimit"
      :fixed-bandwidths="fixedBandwidths"
      :fixed-speed-service="showRateLimitWarning"
      :higher-speed-url="higherSpeedUrl"
      :rate-limit-is-editable="rateLimitIsNotReadOnly && !serviceIsIx" />
  </div>
</template>

<script>
// External Tools
import { mapState, mapGetters, mapActions } from 'vuex'
import { DateTime } from 'luxon'
import { set, merge } from 'lodash'
// Internal Tools
import sdk from '@megaport/api-sdk'
import captureSentryError from '@/utils/CaptureSentryError.js'
import { convertProductType, deepClone, isCloudConnection } from '@/helpers.js'
import { getSurveyLink } from '@/utils/surveys.js'
// Components
import InputVlanComponent from '@/components/InputVlan.vue'
import InputRatelimitComponent from '@/components/InputRatelimit.vue'
import MacAddressComponent from '@/components/ui-components/MacAddress.vue'
import SimpleConfirmationDialog from '@/components/ui-components/SimpleConfirmationDialog.vue'
import DealSelect from '@/components/DealSelect.vue'
import PortTermComponent from '@/components/PortTerm.vue'
// Globals
import { G_PRODUCT_TYPE_IX } from '@/Globals'

export default {
  name: 'ConnectionConfig',

  components: {
    'input-vlan': InputVlanComponent,
    'input-ratelimit': InputRatelimitComponent,
    'mac-address': MacAddressComponent,
    'simple-confirmation-dialog': SimpleConfirmationDialog,
    'deal-select': DealSelect,
    'port-term': PortTermComponent,
  },

  inject: ['disabledFeatures', 'isFeatureEnabled'],

  props: {
    connectionForm: {
      type: Object,
      required: true,
    },
    service: {
      type: Object,
      required: true,
    },
    aEndPort: {
      type: Object,
      required: false,
      default: null,
    },
    bEndPort: {
      type: Object,
      required: false,
      default: null,
    },
    bEndConfig: {
      type: Object,
      required: false,
      default: null,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    approvalPending: {
      type: Object,
      required: true,
    },
    minRateLimit: {
      type: Number,
      required: true,
    },
    maxRateLimit: {
      type: Number,
      required: true,
    },
    deals: {
      type: Array,
      default() {
        return []
      },
    },
  },

  data() {
    return {
      enforcedSpeedChange: false,
      usingAzurePeering: false,
      showPassword: false,
      showShutdownModal: false,
      showTermModal: false,
    }
  },

  computed: {
    ...mapState('IXTypes', ['ixTypes']),
    ...mapState('Auth', { userEmail: state => state.data.email }),
    ...mapGetters('Company', ['companyUid']),
    ...mapGetters('Auth', ['hasFeatureFlag', 'isManagedAccount', 'isPartnerVantage']),

    // ----- Service-related computed properties -----
    productName() {
      return convertProductType(this.service.productType)
    },
    serviceIsIx() {
      return this.service.productType === G_PRODUCT_TYPE_IX
    },

    // ----- A-End-related computed properties -----
    aEndIsPort() {
      return this.aEndPort.productType === this.G_PRODUCT_TYPE_MEGAPORT
    },
    aEndIsMve() {
      return this.aEndPort.productType === this.G_PRODUCT_TYPE_MVE
    },
    showAEndVNics() {
      return this.aEndIsMve && this.aEndPort.vnics.length > 1
    },
    aEndOwned() {
      return this.aEndPort?.companyUid === this.companyUid
    },
    canEditAEndVlan() {
      // VLAN field is not editable for MCR/IX ends
      if (!this.aEndPort || this.aEndPort.virtual || this.serviceIsIx) return false
      return this.aEndPort.companyUid === this.companyUid
    },
    /**
     * A-End VLAN field is disabled for any IBM 2.0 connections
     */
    aEndVlanFieldDisabled() {
      return this.bEndConfig.partnerConfig?.csp_name === 'IBM'
    },

    // ----- B-End-related computed properties -----
    bEndIsMve() {
      return this.bEndPort.productType === this.G_PRODUCT_TYPE_MVE
    },
    showBEndVNics() {
      return this.bEndIsMve && this.bEndPort.vnics.length > 1
    },
    canEditBEndVlan() {
      // VLAN field is not editable for MCR ends
      if (!this.bEndPort || this.bEndPort.virtual) return false
      return this.bEndPort.companyUid === this.companyUid
    },
    bEndOwned() {
      return this.bEndPort?.companyUid === this.companyUid
    },

    // ----- Rate-limit-related computed properties -----
    fixedBandwidths() {
      if (this.service.resources?.csp_connection?.bandwidths) {
        return this.service.resources.csp_connection.bandwidths
      }

      // Google and IBM B-End should be a set of bandwidths
      // TODO: Check whether this should be the case for any non-VROUTER entries
      if (Array.isArray(this.service.resources?.csp_connection)) {
        const cspRet = this.service.resources.csp_connection.find(connection => {
          return connection.connectType === 'GOOGLE' || connection.csp_name === 'IBM' || connection.csp_name === 'Alibaba'
        })

        if (cspRet) return cspRet.bandwidths
      }

      return false
    },
    showRateLimitWarning() {
      return ['GCI', 'AWSHC'].includes(this.bEndPort?.connectType)
    },
    rateLimitIsNotReadOnly() {
      return !this.showRateLimitWarning && !this.enforcedSpeedChange && this.approvalPending.type !== 'SPEED_CHANGE'
    },
    higherSpeedUrl() {
      return getSurveyLink('higherSpeed', {
        email: this.userEmail,
        aEnd: this.aEndPort._location?.formatted?.long ?? 'Unknown',
        bEnd: this.bEndPort._location?.formatted?.long ?? 'Unknown',
      })
    },

    // ----- Term-related computed properties -----
    /**
     * Determine whether the current user is allowed to edit the contract term and rate limit
     */
    allowContractTermEdit() {
      // Exit early if the service is not in a term-ready state
      if (!this.serviceStatusIsTermReady) return false

      // Allow term editing for Private VXCs
      if (this.aEndOwned && this.bEndOwned) return true

      // Prevent term editing for B-End owners of third-party Service Key connections
      return this.aEndOwned && !this.bEndOwned
    },
    serviceStatusIsTermReady() {
      const relevantProvisioningStatuses = [
        this.G_PROVISIONING_CANCELLED,
        this.G_PROVISIONING_CANCELLED_PARENT,
        this.G_PROVISIONING_CONFIGURED,
        this.G_PROVISIONING_DEPLOYABLE,
        this.G_PROVISIONING_LIVE,
      ]

      return relevantProvisioningStatuses.includes(this.service.provisioningStatus)
    },
    showTerm() {
      if (!this.serviceStatusIsTermReady) return false

      return this.service.contractTermMonths > 1
    },
    termDisplay() {
      if (!this.serviceStatusIsTermReady) {
        return this.$t('ports.contract-terms')
      }

      if (this.showTerm) {
        return this.$tc('general.count-months', this.service.contractTermMonths, { count: this.service.contractTermMonths })
      }

      return this.$t('port-term.no-minimum-term')
    },

    // ----- Permissions-related computed properties -----
    isCloudConnection() {
      return isCloudConnection(this.bEndPort.connectType)
    },
    showDeals() {
      // Exit early if none of the necessary flags are present
      if (['VANTAGE_PARTNER', 'VANTAGE_MANAGED_ACCOUNT'].every(flag => !this.isFeatureEnabled(flag))) return false

      return this.isManagedAccount
    },

    // ----- Other computed properties -----
    showEcix() {
      if (!this.serviceIsIx) return false

      if (!this.ixTypes[this.service.locationId]) {
        this.getIxType(this.service.locationId)

        return false
      } else {
        const foundInfo = this.ixTypes[this.service.locationId].find(loc => loc.name === this.service.networkServiceType)

        return foundInfo ? foundInfo.ecix : false
      }
    },
    pendingSpeedApprovalMessage() {
      let reason = null

      if (this.approvalPending.type === 'SPEED_CHANGE' && this.approvalPending.internal) {
        if (this.approvalPending.permitted) {
          reason = this.$t('connections.speed-change-pending-your-approval')
        } else {
          reason = this.$t('connections.non-edit-reason', {
            reason: this.$t('connections.speed-change-pending-company-approval'),
          })
        }
      } else if (this.approvalPending.type === 'SPEED_CHANGE' && !this.approvalPending.internal) {
        reason = this.$t('connections.non-edit-reason', {
          reason: this.$t('connections.pending-speed-approval', {
            approvalFrom: this.approvalPending.message ? this.approvalPending.message : `(${this.$t('general.unknown')})`,
          }),
        })
      }

      if (!reason) return null

      const split = reason.split('|')

      let assembledReason = ''

      if (split.length === 2) {
        assembledReason += split[0]
        assembledReason += '<br/><br/><b>'
        assembledReason += split[1]
        assembledReason += '</b>'
      } else {
        assembledReason = split[0]
      }
      return assembledReason
    },
    canApply() {
      return this.editable || (this.approvalPending.type && this.approvalPending.internal && this.approvalPending.permitted)
    },

    /** Combine the full data for the current service with the form data */
    editingService() {
      return merge(this.service, this.connectionForm)
    },

    /** Get the original unaltered VLAN value for the connection */
    getOriginalAEndVlan() {
      return this.aEndPort?.associatedVxcs?.find(vxc => vxc.productUid === this.service.productUid)?.aEnd?.vlan
    },
    /** Get the original unaltered inner VLAN value for the connection */
    getOriginalAEndInnerVlan() {
      return this.aEndPort?.associatedVxcs?.find(vxc => vxc.productUid === this.service.productUid)?.aEnd?.innerVlan
    },
    /** Get the original unaltered VLAN value for the connection */
    getOriginalBEndVlan() {
      return this.bEndPort?.associatedVxcs?.find(vxc => vxc.productUid === this.service.productUid)?.bEnd?.vlan
    },
    /** Get the original unaltered inner VLAN value for the connection */
    getOriginalBEndInnerVlan() {
      return this.bEndPort?.associatedVxcs?.find(vxc => vxc.productUid === this.service.productUid)?.bEnd?.innerVlan
    },
  },

  watch: {
    bEndConfig: {
      handler() {
        if (this.bEndConfig?.partnerConfig?.serviceKey) {
          sdk.instance
            .product()
            .nutanix(this.bEndConfig.partnerConfig.serviceKey)
            .then(data => {
              if (this.service.rateLimit !== data.bandwidth) {
                this.connectionFormChange('rateLimit', data.bandwidth)
                this.enforcedSpeedChange = true
              }
            })
            .catch(e => {
              console.warn('Error looking up Nutanix key: ', e)
              if (e.status !== 400) {
                captureSentryError(e)
              }
            })
        }
      },
      deep: true,
      immediate: true,
    },
    'connectionForm.bEnd.innerVlan': {
      handler(newValue) {
        if (newValue) {
          this.usingAzurePeering = true
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions('IXTypes', ['getIxType']),

    usingAzurePeeringChanged(value) {
      this.usingAzurePeering = value
      this.$refs.innerVlan.clearValidate()
      this.$emit('updateAzurePeering', value)
    },
    connectionFormChange(keyPath, value) {
      const newObject = deepClone(this.connectionForm)

      set(newObject, keyPath, value)

      this.$emit('update:connectionForm', newObject)
    },
    shutdownChange(shutdown) {
      // Only show popup if we click shutdown and we were enabled
      if (shutdown && !this.connectionForm.shutdown) {
        this.showShutdownModal = true
      }

      // Because Element UI sucks we need to propagate the change regardless otherwise it breaks
      this.connectionFormChange('shutdown', shutdown)
    },
    cancelShutdown() {
      this.showShutdownModal = false
      this.connectionFormChange('shutdown', false)
    },
    formatDate(date) {
      return DateTime.fromMillis(date).toFormat('y-LL-dd')
    },
    handleVNicChangeForEnd(prefix, newVnicIndex) {
      // Change the vNicIndex value
      this.$nextTick(() => {
        this.connectionFormChange(`${prefix}End.vNicIndex`, newVnicIndex)
      })

      let defaultVlanValue = !this[`${prefix}EndVNicUntaggedVxc`] ? -1 : 0

      this.connectionFormChange(`${prefix}End.innerVlan`, defaultVlanValue)

      // Change the VLAN to the new vNic's VLAN
      this.$nextTick(() => {
        const newVnicVlan = this[`${prefix}EndPort`].vnics[newVnicIndex].vlan

        this.connectionFormChange(`${prefix}End.vlan`, newVnicVlan)
      })

      this.$nextTick(() => {
        const vlanComponent = this.$refs[`${prefix}End.innerVlan`]

        this.$nextTick(() => {
          if (vlanComponent) vlanComponent.triggerValidation()
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.non-editable {
  width: fit-content;
  padding: 1rem 2rem;
  border: 1px solid var(--color-warning);
  border-radius: var(--border-radius-base);
  background-color: var(--color-white);
  line-height: 2rem;
  color: var(--color-text-primary);

  &.ml-250px {
    margin-left: 250px;
  }
}
.azure-settings-group {
  border: 1px solid var(--border-color-base);
  border-radius: var(--border-radius-base);
  padding: 1rem 1.4rem 0 1rem;
  margin-bottom: 1rem;
  color: var(--color-text-primary);
}
.max-width-700px {
  max-width: 700px;
}
</style>
